import {
  ItemRecipientType,
  ItemSubjectType,
  ResponseSubject,
  ResponseSubjectType,
  User,
} from "../../types";

export default function isCompatible(
  responseSubjectType: ResponseSubjectType,
  itemSubjectType: ItemSubjectType,
  itemRecipientType?: ItemRecipientType,
  author?: User,
  isAuthorTeamLead?: boolean,
  responseSubject?: ResponseSubject,
): boolean {
  // General feedback is always compatible with general questions
  if (responseSubjectType === ResponseSubjectType.General) {
    return itemSubjectType === ItemSubjectType.General;
  }

  if (!responseSubject || !itemRecipientType) {
    switch (itemSubjectType) {
      case ItemSubjectType.PeerSelf:
      case ItemSubjectType.PeerOnly:
      case ItemSubjectType.LeadSelf:
      case ItemSubjectType.LeadOnly:
      case ItemSubjectType.NonLeadSelf:
      case ItemSubjectType.NonLeadOnly:
        return true;
      default:
        return false;
    }
  }

  const isLead = responseSubject.isTeamLead;
  const isSelf = responseSubject.subjectUserId === author?.id;

  switch (itemRecipientType) {
    case ItemRecipientType.TeamLeaders:
      if (!isAuthorTeamLead) {
        return false;
      }
      break;
    case ItemRecipientType.NonLeaders:
      if (isAuthorTeamLead) {
        return false;
      }
      break;
    default:
      break;
  }

  switch (itemSubjectType) {
    case ItemSubjectType.PeerSelf:
      return true;
    case ItemSubjectType.PeerOnly:
      return !isSelf;
    case ItemSubjectType.LeadSelf:
      // Only show lead self-evaluation questions to leads evaluating themselves
      return isLead;
    case ItemSubjectType.LeadOnly:
      // Only show lead evaluation questions to non-leads evaluating leads
      return isLead && !isSelf;
    case ItemSubjectType.NonLeadSelf:
      // Only show non-lead self-evaluation questions to non-leads evaluating themselves
      return !isLead;
    case ItemSubjectType.NonLeadOnly:
      // Only show non-lead evaluation questions to leads/non-leads evaluating non-leads
      return !isLead && !isSelf;
    default:
      return false;
  }
}
