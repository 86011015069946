import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import { User, Response } from "../../types";
import { Check, Rule } from "@mui/icons-material";
import { UserAvatar } from "./UserAvatar";
import { getUserName } from "../../DataTransform";

export type UserActionsType = ({ user }: { user: User }) => React.ReactNode;

export interface Props {
  responses?: Response[];
  setSelected?: (selected: string[]) => void;
  selected?: string[];
  UserActions?: UserActionsType;
  users: User[];
  leader?: string;
  campaignDueDate?: Date | null;
}

export const UserList = ({
  responses,
  UserActions,
  selected,
  setSelected,
  users,
  leader,
  campaignDueDate,
}: Props) => {
  const leaderUser = users.find((user) => user.id == leader);

  const isLate = (response: Response) => {
    if (!campaignDueDate || !response.submittedDate) {
      return false;
    }
    return new Date(response.submittedDate) > campaignDueDate;
  };

  function handleToggle(userId: string) {
    if (!selected) {
      return;
    }
    let newChecked = selected;
    if (selected.indexOf(userId) >= 0) {
      newChecked = selected.filter((target) => target !== userId);
    } else {
      newChecked = [...selected, userId];
    }
    setSelected && setSelected(newChecked);
  }

  function Checkmark({ user }: { user: User }) {
    const response = responses?.find(
      (response) => response.authorId === user.id,
    );

    if (response) {
      if (response.submitted) {
        if (isLate(response)) {
          return <Check color="error" sx={{ mr: 1 }} />;
        } else {
          return <Check sx={{ mr: 1 }} />;
        }
      } else if (response.hasAnswers) {
        return <Rule sx={{ mr: 1 }} />;
      }
    }
    return <Check sx={{ mr: 1, visibility: "hidden" }} />;
  }

  function Content({ user }: { user: User }) {
    return (
      <>
        {selected && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected.indexOf(user.id) !== -1}
              tabIndex={-1}
            />
          </ListItemIcon>
        )}
        <Checkmark user={user} />
        <ListItemText primary={getUserName(user)} />
      </>
    );
  }

  function Button({
    children,
    user,
  }: {
    user: User;
    children: React.ReactNode;
  }) {
    return (
      <ListItemButton
        role={undefined}
        onClick={() => handleToggle(user.id)}
        dense
      >
        {children}
      </ListItemButton>
    );
  }

  return (
    <List dense>
      {users.map((user, index) => (
        <ListItem
          key={index}
          secondaryAction={UserActions && UserActions({ user })}
        >
          <ListItemAvatar>
            <UserAvatar
              user={user}
              starred={leaderUser && user === leaderUser}
            />
          </ListItemAvatar>
          {selected ? (
            <Button user={user}>
              <Content user={user} />
            </Button>
          ) : (
            <Content user={user} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

UserList.propTypes = {
  checked: PropTypes.arrayOf(PropTypes.string),
  setSelected: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  UserActions: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  leader: PropTypes.string,
};
